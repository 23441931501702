/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import {
  FaInstagramSquare,
  FaTwitterSquare,
  FaGithubSquare,
} from "react-icons/fa"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            github
            instagram
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <>
      <div
        style={{
          display: `flex`,
          marginBottom: rhythm(1 / 2),
        }}
      >
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author.name}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`,
          }}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
        <p>
          Written by <strong>{author.name}</strong> {author.summary}
        </p>
      </div>
      <div style={{ textAlign: "center", fontSize: "1.2rem" }}>
        <a
          style={{
            textDecoration: "none",
            boxShadow: "none",
            marginRight: rhythm(1 / 2),
            color: '#1da1f2',
            fontSize: '1.8em'
          }}
          href={`https://twitter.com/${social.twitter}`}
        >
          <FaTwitterSquare style={{ height: rhythm(1.2) }} />
        </a>
        <a
          style={{
            marginRight: rhythm(1 / 2),
            textDecoration: "none",
            boxShadow: "none",
            color: '#333',
            fontSize: '1.8em'
          }}
          href={`https://github.com/${social.github}`}
        >
          <FaGithubSquare style={{ height: rhythm(1.2) }} />
        </a>
        <a
          style={{
            textDecoration: "none",
            boxShadow: "none",
            color: '#405de6',
            fontSize: '1.8em'
          }}
          href={`https://instagram.com/${social.instagram}`}
        >
          <FaInstagramSquare />
        </a>
      </div>
    </>
  )
}

export default Bio
